export const red = '#FF5A5F'
export const lightRed = '#FFC2C4'
export const darkRed = '#660003'
export const lightGreen = '#D7EED2'
export const darkGreen = '#234A1C'
export const lightBlue = '#BCDDF1'
export const darkBlue = '#0E2F43'
export const blue = '#3597D4'
export const white = '#FFFFFF'
export const lightYellow = '#FBED89'
export const yellow = '#FDE74C'
export const darkYellow = '#71650a'
export const green = '#8ED081'
export const orange = '#F9B310'

export const cursive = '"Lobster Two", cursive'
export const sansSerif = '"Roboto", sans-serif'

export const font10 = '0.625rem'
export const font12 = '0.75rem'
export const font14 = '0.875rem'
export const font16 = '1rem'
export const font20 = '1.25rem'
export const font24 = '1.5rem'
export const font28 = '1.75rem'
export const font32 = '2rem'
export const font48 = '3rem'
export const font64 = '4rem'
